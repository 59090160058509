.get-started-container {
  display: flex;
  width: 100%;
  position: relative;
}

.get-started-container .get-started-input {
  width: 24vw;
  box-shadow: 1px 1px 2px rgb(181 181 181);
  height: 40px;
  max-height: 60px;
  width: calc(100% - 100px);
}

@media screen and (max-width: 1150px) {
  .container {
    display: inline-flex;
    width: 100%;
  }
  .get-started-container .get-started-input {
    width: 100%;
    box-shadow: 1px 1px 2px rgb(173 173 173);
    height: 40px;
  }
}
